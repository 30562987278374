@font-face {
    font-family: 'BrixSans';
    src: url('assets/font/BrixSansRegular.otf') format('opentype');
}

@font-face {
    font-family: 'BrixSansBold';
    font-weight: bold;
    src: url('assets/font/BrixSansBold.otf') format('opentype');
}

@font-face {
    font-family: 'BrixSansBlack';
    src: url('assets/font/BrixSansBlack.otf') format('opentype');
}

html,
body {
    font-family: 'BrixSans' !important;
    margin: 0px;
    padding: 0 !important;
    -webkit-font-smoothing: antialiased;
}

.flex {
    display: flex;
}

.cta-button {
    background: #37a58c;
    color: white;
    position: relative;
}

button:disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

button:disabled label {
    cursor: not-allowed !important;
}

.cta-button:after {
    position: absolute;
    right: 15px;
    top: 13px;
    content: url('assets/images/arrow_right.svg');
}

.cta-button {
    cursor: pointer;
    font-family: 'BrixSansBold';
    font-size: 20px;
    border: none;
    padding: 10px 40px 10px 25px;
    text-transform: capitalize;
}

.rehau-logo {
    height: 100px;
}

.rehau-logo img {
    padding: 25px 100px;
}

.headline-wrapper {
  height: 100px;
  padding: 25px 100px;
  background-color: #f2f2f2;
}

.column {
    flex-direction: column;
}

.flex-grow-1 {
    flex: 1;
}

.full-height {
    height: 100vh;
}

h1 {
    font-family: 'BrixSansBlack';
    margin-block-end: 0;
    font-size: 28px;
}

h2 {
    font-size: 18px;
    margin-block-start: 10px;
    font-family: 'BrixSans';
    font-weight: 100;
}

.background {
    background-image: url('/assets/images/reguard.jpg');
    height: 100%;
    background-size: cover;
    position: relative;
}

.box {
    max-width: 350px;
    min-height: 200px;
    background: white;
    bottom: 30vh;
    right: 5vw;
    padding: 25px;
    position: absolute;
}

.box h1 {
    text-align: center;
}

.box button {
    margin-top: 10px;
    float: right;
}

@keyframes sweep {
    0% {
        opacity: 0;
        margin-left: -10px;
    }
    100% {
        opacity: 1;
        margin-left: 0px;
    }
}

details[open] summary~* {
    animation: sweep 0.5s ease-in-out;
}

@media only screen and (max-width: 768px) {
    .logout {
        line-height: inherit !important;
    }
    .content {
        padding: 0px 20px !important;
    }
    .headline {
        padding: 0px 40px !important;
    }
    .selects {
        max-width: 100vw !important;
        margin-top: 20px !important;
    }
    span.key,
    span.value {
        font-size: 16px !important;
    }
    span.value {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
    .changeDevice {
        float: none !important;
        padding-left: 20px;
    }
    .mobile-column {
        flex-direction: column;
    }
    .online-indicator {
        margin-left: 0 !important;
    }
    .box {
        bottom: inherit !important;
        right: 0 !important;
        left: 0 !important;
        max-width: none !important;
    }
    .rehau-logo img {
        padding: 0 !important;
        top: 20px;
        right: 15px;
        position: relative;
        display: block;
        width: auto;
        margin: auto;
    }
    .box button {
        margin-left: auto;
        margin-right: auto;
        display: block;
        float: none;
    }
}
